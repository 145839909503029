import * as React from 'react'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import {
  useDialogStyles,
  useContainerStyles,
  useDialogTitleStyles,
  useDialogContentStyles,
  useDialogActionStyles,
} from 'app/frontend/components/material/modal/styles'
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc' // TODO ALPACA-757
import { getModalOptions } from 'app/frontend/components/material/modal/modal-reducer'
import {
  clearRefocus as clearRefocusAction,
  hideModal as hideModalAction,
} from 'app/frontend/components/material/modal/modal-actions'
import { ButtonIconMaterial } from 'app/frontend/components/material/button/button-icon'
import { sendEvent } from 'app/frontend/helpers/mixpanel'

/**
 * Arguments to be passed into the render prop functions: leftAction, rightAction, belowDialog, and children,
 * which supply the various parts of the modal
 */
export interface RenderProps<ModalOptions> {
  options: ModalOptions
  hideModal: () => void
}

/**
 * A function to produce some part of the modal contents
 */
export type PartSupplier<ModalOptions> = (renderProps: RenderProps<ModalOptions>) => React.ReactNode

export interface OwnProps<ModalOptions> {
  /**
   * A unique name for the modal
   */
  name: string
  /**
   * True to make the modal take the full screen. Otherwise it will be sized to fit the content.
   */
  fullScreen?: boolean
  /**
   * True to make the modal not have padding.
   */
  noPad?: boolean
  /**
   * CSS class name to apply to the Portal containing the modal
   */
  className?: string
  /**
   * ID of the button used to launch the modal. If specified, focus will be returned to this
   * element when the modal is closed.
   */
  openingBtnId?: string
  /**
   * True to hide the close button at the top right of the modal and prevent the modal from being
   * closed if the user clicks on the page outside the modal.
   */
  preventClose?: boolean
  /**
   * Function returning an element to focus upon opening the modal. If not provided, the close
   * button will be focused upon opening the modal, unless preventClose is true and there is no
   * close button, in which case nothing will be focused.
   */
  initialFocus?: () => HTMLElement // if provided will set initial modal focus this element
  /**
   * Value to assign to the data-bi attribute of the modal's root div.
   */
  dataBi: string
  /**
   * Function to provide elements to show at the top-left of the modal
   */
  leftAction?: PartSupplier<ModalOptions>
  /**
   * Function to provide elements to show at the top-right of the modal
   */
  rightAction?: PartSupplier<ModalOptions>
  /**
   * Function to provide elements to show below the modal content
   * Note: belowDialog elements are not accessible!
   */
  belowDialog?: PartSupplier<ModalOptions>
  /**
   * Function to provide elements to show in the body of the modal
   */
  children: PartSupplier<ModalOptions>
  /**
   * Event name for close icon button
   */
  hideModalSendEventName?: string
}

interface StateProps<ModalOptions> {
  /**
   * The options to be passed to the modal contents. Present only if the modal is open.
   */
  modalOptions?: ModalOptions
  /**
   * May be set to true to cause this modal to grab focus again after it has lost it
   */
  shouldRefocus?: boolean
}

interface DispatchProps<ModalOptions> {
  hideModalAction: (modalName: string, options?: ModalOptions) => void
  clearRefocusAction: (modalName: string) => void
}

type PropsWithoutApollo<ModalOptions> = OwnProps<ModalOptions> &
  StateProps<ModalOptions> &
  DispatchProps<ModalOptions>
type Props<ModalOptions> = WithApolloClient<PropsWithoutApollo<ModalOptions>>

/**
 * A (mostly) accessible modal component supporting the standard set of Alta modal styles.
 */
export const _Modal = <ModalOptions extends {}>(props: Props<ModalOptions>): JSX.Element => {
  const {
    name,
    initialFocus,
    openingBtnId,
    leftAction,
    rightAction,
    preventClose,
    belowDialog,
    dataBi,
    children,
    hideModalSendEventName,
  } = props

  const modalId = `modal-${name}`
  const [closeButton, setCloseButton] = useState(null)

  const { shouldRefocus, modalOptions } = useSelector<any, StateProps<ModalOptions>>(state => {
    const modalOptionsFromState = getModalOptions(state, name)
    return {
      modalOptions: modalOptionsFromState,
      shouldRefocus: modalOptionsFromState && modalOptionsFromState.shouldRefocus,
    }
  }, shallowEqual)

  const dispatch = useDispatch()
  const clearRefocus = () => dispatch(clearRefocusAction(name))

  const isOpen = !!modalOptions

  /**
   * Helper to focus the appropriate element inside the modal
   */
  const grabFocus = () => {
    const initialFocusElt = initialFocus && initialFocus()
    if (initialFocusElt) {
      initialFocusElt.focus()
    } else if (closeButton) {
      closeButton.focus()
    } else {
      // if we dont have initial focus or close button to focus on
      // we will just focus on the first focusable element

      // We need the setTimeout so that we wait for the modal to
      // exist on the page
      setTimeout(() => {
        const elems = [
          ...document.body
            .querySelector(`#${modalId}`)
            .querySelectorAll(
              'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), ' +
                'textarea:not([disabled]), [tabindex]:not([tabindex="-1"]), [data-test]:not([data-test="sentinelStart"])'
            ),
        ]
        const firstElement = elems[0] as any
        firstElement?.focus()
      }, 0)
    }
  }

  // Grab focus when the modal is opened
  useEffect(() => {
    if (isOpen) {
      grabFocus()
    }
  }, [isOpen, closeButton, initialFocus])

  // Grab focus when an external component tells us to
  useEffect(() => {
    if (shouldRefocus) {
      // Clear the refocus so we don't enter an endless refocus loop
      clearRefocus()
      if (isOpen) {
        grabFocus()
      }
    }
  }, [name, shouldRefocus, isOpen, closeButton, initialFocus])

  // When closed or unmounted return focus to the opening button
  useEffect(() => {
    if (isOpen) {
      return () => {
        if (!isOpen && openingBtnId && document.getElementById(openingBtnId)) {
          document.getElementById(openingBtnId).focus()
        }
      }
    }
  }, [isOpen, openingBtnId])

  const styles = useDialogStyles(props)
  const containerStyles = useContainerStyles()
  const dialogTitleStyles = useDialogTitleStyles(props)
  const dialogContentStyles = useDialogContentStyles(props)
  const dialogActionStyles = useDialogActionStyles()

  const hideThisModal = React.useCallback(() => {
    if (hideModalSendEventName) {
      sendEvent(hideModalSendEventName)
    }
    dispatch(hideModalAction(name, modalOptions))
  }, [hideModalSendEventName, name, modalOptions])

  const renderProps = { options: modalOptions, hideModal: hideThisModal }

  // DialogTitle, DialogContent and DialogActions are all wrapped with isOpen
  // This is necessary, because if not and the modal is not fullscreen,
  // when we close the modal, we will see a flash of an empty modal instead of nothing

  return (
    <Dialog
      onClose={() => {
        if (!preventClose) {
          hideThisModal()
        }
      }}
      // we roll our own auto focus
      disableAutoFocus={true}
      fullScreen={props.fullScreen}
      open={isOpen}
      classes={styles}
      className={props.className}
      aria-labelledby="modalTitle"
      data-bi={props.dataBi}
    >
      {isOpen && (
        <DialogActions classes={dialogTitleStyles}>
          <div className={containerStyles.container}>
            <div className={containerStyles.leftAction}>
              {leftAction ? leftAction(renderProps) : null}
            </div>
            <div className={containerStyles.rightAction}>
              {!rightAction && !preventClose ? (
                <div>
                  <ButtonIconMaterial
                    onClick={hideThisModal}
                    icon={
                      <svg>
                        <use xlinkHref="#icon-clear" />
                        <title>Close modal</title>
                      </svg>
                    }
                    data-test="close-modal"
                    ariaLabel="Close modal"
                    setRef={setCloseButton}
                    dark={true}
                  />
                </div>
              ) : null}
              {rightAction && rightAction(renderProps)}
            </div>
          </div>
        </DialogActions>
      )}
      {isOpen && (
        <DialogContent classes={dialogContentStyles}>
          <div id={modalId} data-bi={dataBi}>
            {children(renderProps)}
          </div>
        </DialogContent>
      )}
      {isOpen && belowDialog ? (
        <DialogActions classes={{ root: dialogActionStyles.root }}>
          <div className={dialogActionStyles.container}>{belowDialog(renderProps)}</div>
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

/**
 * Create a modal component with specific modal options type. Necessary because there is no way to specify
 * a generic type param to the HoC returned from react-redux's connect()
 * :(
 */
export const createModal = <ModalOptions extends {}>(): React.ComponentType<
  OwnProps<ModalOptions>
> => {
  return withApollo<PropsWithoutApollo<ModalOptions>>(_Modal)
}
