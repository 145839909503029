/**
 * This component uses the Question Editor API
 * https://reference.learnosity.com/questioneditor-api/index
 */
import * as React from 'react'
import * as classNames from 'classnames'
import { connect } from 'react-redux'
import { Tooltip } from 'app/frontend/components/tooltip'
import { Icon } from 'app/frontend/components/material/icon'
import { tns } from 'app/frontend/helpers/translations/i18n'

import { setLearnosityIsValid, putLearnosityIsReady } from './learnosity-actions'
import { getLearnosityCredentials } from './learnosity-reducer'
import * as styles from './learnosity.css'
import {
  LEARNOSITY_MOUNT_ID,
  DEFAULT_MC_QUESTION_DATA,
  DEFAULT_FR_QUESTION_DATA,
  isMultipleChoice,
  isFreeResponse,
  INVALID_LEARNOSITY,
} from './learnosity-helpers'

const t = tns('teach:learnosity_editor')

type OwnProps = React.PropsWithRef<{
  questionData?: Commons.ILearnosityPayload
  questionType?: Content.LearnosityType
  setLoadingState?: (isLoading: Status) => void
}>

type StateProps = {
  securityPacket: any
}

type DispatchProps = {
  setLearnosityIsValid: (isValid: boolean) => void
  putLearnosityIsReady: (isReady: boolean) => void
}

type Props = StateProps & DispatchProps & OwnProps

export enum Status {
  Loading = 'LOADING',
  Ready = 'READY',
  Error = 'ERROR',
}

type State = {
  status: Status
}

export interface ILearnosityEditor {
  getJson: () => Commons.ILearnosityPayload
}

export class _LearnosityEditor extends React.Component<Props, State> implements ILearnosityEditor {
  private learnosityQuestionEditor: ILearnosityQuestionEditorInstance
  state = { status: Status.Loading }

  /**
   * If credentials are present, initialize a Learnosity instance.
   * Security credentials are fetched on page load by learnosity-saga.ts.
   */
  async componentDidMount(): Promise<void> {
    // Reset the flags in the Redux store
    this.props.setLearnosityIsValid(false)
    this.props.putLearnosityIsReady(false)

    if (this.props.securityPacket) {
      await this.initialize()
    }
  }

  /**
   * If credentials just appeared, intialize a Learnosity instance.
   * If the status just changed to ERROR, log it.
   */
  async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    const securityPacketLoaded = !prevProps.securityPacket && this.props.securityPacket
    const newError = prevState.status !== Status.Error && this.state.status === Status.Error

    if (this.state.status === Status.Loading && securityPacketLoaded) {
      await this.initialize()
    } else if (
      prevProps.questionData !== this.props.questionData &&
      this.state.status !== Status.Error
    ) {
      await this.initialize()
    } else if (newError) {
      console.error('Learnosity did not load properly', { internalIssueId: 'CE-2474' })
    }
  }

  /**
   * Clean up the learnosity instance upon removal
   */
  componentWillUnmount() {
    if (this.learnosityQuestionEditor) {
      this.learnosityQuestionEditor.destroy()
    }
    this.props.putLearnosityIsReady(false)
  }

  /**
   * Initalize the learnosity editor with a 10 second timeout.
   * Set the component state to READY or ERROR, whichever returns first.
   */
  private initialize = async (): Promise<void> => {
    const options = this.getInitOptions()
    this.props.setLoadingState(Status.Loading)
    const status = await Promise.race([this.initEditor(options), this.initTimeout()])
    this.setState({ status })
    this.props.setLoadingState(status)
  }

  /**
   * Set up the options needed to initialize the LearnosityQuestionEditor
   * We hide a lot of them to simplify the interface for instructors.
   */
  private getInitOptions = (): any => {
    const { securityPacket, questionData, questionType } = this.props

    let widgetJson
    let hiddenSections = []
    let hidden = []

    if (isMultipleChoice(questionType, questionData)) {
      widgetJson = questionData || DEFAULT_MC_QUESTION_DATA
      hiddenSections = ['more_options.heading', 'more_options.divider']
    } else if (isFreeResponse(questionType, questionData)) {
      widgetJson = questionData || DEFAULT_FR_QUESTION_DATA
      hiddenSections = ['scoring', 'details', 'text_blocks', 'layout', 'more_options']
      hidden = [
        'validation.penalty',
        'validation.min_score_if_attempted',
        'validation.unscored',
        'validation.valid_response.value.aria_label',
        'validation.scoring_type',
        'instant_feedback',
        'feedback_attempts',
        'is_math',
        'math_renderer',
      ]
    }
    return {
      configuration: {
        consumer_key: securityPacket.consumer_key,
      },
      ui: {
        template_name: false,
        change_button: false,
        help_button: false,
        source_button: false,
      },
      rich_text_editor: {
        toolbar_settings: {
          ltr_toolbar: [
            {
              items: ['Bold', 'Italic', '-', 'LrnUnderlinedIndicator', '-', 'RemoveFormat'],
              name: 'basicstyles',
            },
            {
              items: ['NumberedList', 'BulletedList', 'Indent', 'Outdent'],
              name: 'list',
            },
            {
              items: ['Image', 'LrnMath', 'SpecialChar'],
              name: 'insert',
            },
            {
              items: ['Undo', 'Redo'],
              name: 'clipboard',
            },
            {
              items: ['Styles'],
              name: 'style',
            },
            {
              items: ['Sourcedialog'],
              name: 'mode',
            },
          ],
        },
      },
      base_question_type: {
        hidden_sections: hiddenSections,
        hidden,
      },
      widget_json: widgetJson,
    }
  }

  /**
   * Given a set of options, initialize the
   * LearnosityQuestionEditor and assign it to
   * a property on the class instance.
   *
   * If successful, set the status as READY.
   * Otherwise, set the status as ERROR.
   *
   * Set an event listener to validate user changes.
   */
  private initEditor = (options: any): Promise<Status.Ready | Status.Error> =>
    new Promise<Status.Ready | Status.Error>(resolve => {
      if (!window.LearnosityQuestionEditor) {
        console.error('Learnosity is not on the window', { internalIssueId: 'CE-2474' })

        resolve(Status.Error)
      }

      const callbacks: LearnosityCallbacks = {
        readyListener: () => {
          this.learnosityQuestionEditor.on('preview:changed', () => {
            const isValid = this.checkValidation()
            this.props.setLearnosityIsValid(isValid)
          })

          resolve(Status.Ready)
          this.props.putLearnosityIsReady(true)
        },
        errorListener: error => {
          console.error('Learnosity Question Editor failed to initialize', {
            internalIssueId: 'CE-2474',
            error,
          })

          resolve(Status.Error)
        },
      }

      this.learnosityQuestionEditor = window.LearnosityQuestionEditor.init(
        options,
        `#${LEARNOSITY_MOUNT_ID}`,
        callbacks
      )
    })

  /**
   * Respond with status ERROR after 10 seconds.
   */
  private initTimeout = async (): Promise<Status.Error> =>
    new Promise<Status.Error>(resolve => setTimeout(() => resolve(Status.Error), 10000))

  /**
   * Trigger validaton on the LearnosityQuestionEditor instance
   */
  private checkValidation = (): boolean =>
    !!this.learnosityQuestionEditor &&
    this.learnosityQuestionEditor.checkValidation().has_validation

  /**
   * Get the valid Learnosity JSON for the current input.
   * If the input is invalid, throw an error instead.
   *
   * This method is meant to be triggered externally (via ref)
   * and not within this file.
   */
  public getJson = (): Commons.ILearnosityPayload => {
    if (!this.checkValidation()) {
      throw new Error(INVALID_LEARNOSITY)
    }
    return this.learnosityQuestionEditor.getWidget()
  }

  render(): JSX.Element {
    const { questionType, questionData } = this.props
    const { status } = this.state

    if (status === Status.Error) {
      return <div className={styles.error}>{t('learnosity_error')}</div>
    }

    return (
      <div>
        {isFreeResponse(questionType, questionData) && (
          <Tooltip
            title={t('free_response_tooltip')}
            placement="top"
            className={styles.helpTooltip}
          >
            <a
              href="https://docs.learnosity.com/authoring/authorguide/questions/math/formulaV2"
              target="_blank"
              aria-label={t('free_response_link_aria')}
              data-bi="learnosity-formulav2-link"
            >
              <Icon name="icon-help-outline" className={styles.helpIcon} />
            </a>
          </Tooltip>
        )}

        <div className={classNames(styles.learnosity, styles.learnosityAuthWrapper)}>
          <div id={LEARNOSITY_MOUNT_ID} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any): StateProps => ({
  securityPacket: getLearnosityCredentials(state),
})

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  setLearnosityIsValid: (isValid: boolean): void => {
    dispatch(setLearnosityIsValid(isValid))
  },
  putLearnosityIsReady: (isReady: boolean): void => {
    dispatch(putLearnosityIsReady(isReady))
  },
})

export const LearnosityEditor = connect<
  StateProps,
  DispatchProps,
  OwnProps & { ref: React.RefObject<any> }
>(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(_LearnosityEditor)
